
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {ThemeProvider} from 'styled-components';
import theme from './Utils/theme';
import GlobalStyles from './Utils/Global';

import Loader from './Components/UI/Loader/Loader';

const Wrapper = styled.div`
  with: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const root = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme= {theme}>
          <>
          <Wrapper>
            <Loader></Loader>
          </Wrapper>
          </>
          
        </ThemeProvider>
  ,
  root
)

  
  ReactDOM.render(
      <Router>
        <ThemeProvider theme= {theme}>
          <>
            <App /> 
            <GlobalStyles></GlobalStyles>
          </>
          
        </ThemeProvider>

        </Router>,
      root
        
  );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
