import React from "react";
import './App.css';
import Navbar from './Components/Navbar/Navbar/Navbar';
import Layout from './hoc/Layout/Layouts/Layout';

import { Route, Switch, Redirect } from 'react-router-dom';
import Home from "./Components/Home/Home";
import ConferenceProgram from './Components/About/ConferenceProgram';
import Blog from './Components/Blog/Blog';
import Committee from './Components/Committee/Committee';



function App() {
  
    let routes;
    routes = (
      <Switch>
        <Route exact path = "/" component= {Home}></Route>
        <Route exact path = '/ConferenceProgram' component= {ConferenceProgram}></Route>
        <Route exact path = '/venue' component= {Blog}></Route>
        <Route exact path = '/committee' component= {Committee}></Route>
        <Redirect to="/verify-email" />
      </Switch>
    )
    return <Layout>{routes}</Layout>;
};

export default App;