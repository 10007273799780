import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import Latex from "react-latex";



const LogoWrapper = styled.div`
    padding: 0rem 2rem;
    height: 100%;
    display: flex;
    align-items:center;
    font-weight:700;
    color: white;
    font-size: 1.2rem;
    margin-left:4rem;
`;

const StyledLogoLink = styled(Link)`
    color:white;
    font-size: 1.8rem;

    &:hover{
        text-decoration:none;
        color:#aaa;
    }

    @media (max-width: 768px){
        font-size: 20px;
    }

    @media (max-width: 768px){
        font-size: 20px;
    }
    
`;


function Logo() {
    return (
        <LogoWrapper>

            <StyledLogoLink to='/'>JNJN</StyledLogoLink>
            
        </LogoWrapper>
    )
}

export default Logo
