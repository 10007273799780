import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from 'styled-components';
import './main1.scss'; 


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons';

import PostModal from "../Modals/PostModal";


const Home = () => {

    const [showModal, setShowModal] = useState('close');


    const handleClick = (e) => {
        e.preventDefault();

        switch(showModal){
            case "open":
                setShowModal('close');
                break;

            case "close":
                setShowModal('open');
                break;

                default:
                    setShowModal('close');
                    break;
        }
    };


	return (
    <div className="HomeContainer">
        <div class="hero">
            <div class="content">
            <h1>La première Journée Nationale des Jumeaux Numériques (JNJN)</h1>
                <span>👇</span>
            <div class="">
                <h2>22 et 23 Novembre, 2023</h2>
                <p>L’école nationale d’ingénieurs de Tarbes</p>
                <a  href='https://www.enit.fr/fr/index.html' target="_blank">E.N.I.T - Tarbes</a>
                
                <br/> <br/>
                
            <img style={{width:'300px'}} src="./images/enit-logo.png"></img>
                <h3>La date limite d'inscription était le 30 octobre 2023.</h3>
                <h3>Contacter le président de l'événement!</h3>
                <a target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLScL60EyN431DB_WqrmSAAEi0ZSnodwwj_4VGrPERq6L9cTIxQ/viewform?usp=sf_link">
                
                <button  disabled
                        className="btn btn-outline-dark btn-lg" 
                        // onClick= {handleClick}
                >Deadline ! </button></a>
                
                
            </div>
                <svg class="scroll stagger1" width="40" height="77" viewBox="0 0 40 77">
                    <g id="scroll" transform="translate(-253 -787)">
                    <g id="Rectangle_12" data-name="Rectangle 12" transform="translate(253 787)" fill="none" stroke="#fff" stroke-width="4">
                        <rect width="40" height="77" rx="20" stroke="none"/>
                        <rect x="2" y="2" width="36" height="73" rx="18" fill="none"/>
                    </g>
                    <circle class="circle" id="Ellipse_1" data-name="Ellipse 1" cx="11" cy="11" r="11" transform="translate(262 798)" fill="#fff"/>
                    
                    </g>
                </svg>
                <div class="inner transition2">   
                <p class="organisers"><mark>L'IEEE SMC chapitre français </mark> (les Systèmes,  l'Homme et de la Cybernétique), <mark>la SAGIP</mark>, <mark>InteroVLab</mark>, et <mark>l'ENIT</mark> organisent le premier rassemblement d'universitaires et d'acteurs industriels français dans le domaine du jumeau numérique.</p>
               
                </div>
            </div>
            
            <svg xmlns="http://www.w3.org/2000/svg" className="hero-design" width="686" height="688" viewBox="0 0 686 688">
                <g id="blockdesign" transform="translate(-935 -289)">
                <rect className="square-anim" data-name="Rectangle 2" width="172" height="172" rx="19" transform="translate(1277 289)" fill="#6e00ff" />
                <rect className="square-anim" data-name="Rectangle 10" width="172" height="172" rx="86" transform="translate(1277 461)" fill="#6e00ff" />

                {/* Add Font Awesome brain icon to Rectangle 8 */}
                <rect className="square-anim" data-name="Rectangle 8" width="172" height="172" rx="19" transform="translate(1449 461)" fill="#e5d5fa" />
                <FontAwesomeIcon icon={faBrain} className="brain-icon" />

                <rect className="square-anim" data-name="Rectangle 5" width="172" height="172" rx="19" transform="translate(1277 633)" fill="#6e00ff" />
                <rect className="square-anim" data-name="Rectangle 3" width="172" height="172" rx="19" transform="translate(1107 461)" fill="#fff" />
                <rect className="square-anim" data-name="Rectangle 9" width="172" height="172" rx="86" transform="translate(1107 633)" fill="#fff" />
                <rect className="square-anim" data-name="Rectangle 7" width="172" height="172" rx="19" transform="translate(935 633)" fill="#fff" opacity="0.17" />
                <rect className="square-anim" data-name="Rectangle 4" width="172" height="172" rx="19" transform="translate(1107 805)" fill="#fff" />
                </g>
            </svg>
          
		</div>
          {/* <!-- Middle Section --> */}
          <section class="featured">
            <div class="left">
                {/* <div class="inner transition2">   
                <p class="subtitle">La mission</p>
                <p class="organisers">L'IEEE SMC chapitre (les Systèmes,  l'Homme et de la Cybernétique), la SAGIP, InteroVLab, et l'ENIT organisent le premier rassemblement d'universitaires et d'acteurs industriels français dans le domaine du jumeau numérique.</p>
                </div> */}
                <div class="inner transition2">
                    <p class="subtitle">Les objectifs</p>

                    <p class="featured-desc">La Journée nationale de développement du jumeau numérique est une journée spéciale où les acteurs de divers secteurs se réunissent pour promouvoir et encourager le développement du jumeau numérique au niveau national. Cette journée est consacrée à la sensibilisation, à l'échange d'idées et à la collaboration autour de la création d'un environnement de recherche complet et précis pour les développements de jumeaux numériques. L'événement met en lumière les avantages et les opportunités offerts par le jumeau numérique. Les participants comprennent des experts en technologie, des chercheurs, des représentants du gouvernement, des entreprises privées et des universités.</p>
                </div>
            </div>
            <img class="right transition2" src="./images/976.png.jpg" alt="featured projects"/>
        </section>

        {/* <!-- sections to columnize the skills --> */}
    <section class="skills">
        <div class="skills-container">
            <h1 class="skill-title">Thématiques</h1>
            
            <ul>
                <li class="transition2">
                    <div class="icon-container one">
                        <img src="./images/database.png" alt="UI/UX Icon"/>
                    </div>
                    <p class="skill-title">Données de jumeaux numériques</p>
                    <p class="featured-desc skill-desc">Les données d'un jumeau numérique sont une représentation virtuelle et en temps réel et futuriste d'un objet ou d'un système physique, contenant des informations détaillées sur ses caractéristiques, son état et son comportement.</p>
                </li>
                <li class="transition2">
                    <div class="icon-container two">
                        <img src="./images/model.png" alt="UI/UX Icon"/>
                    </div>
                    <p class="skill-title">Découverte et interopérabilité des modèles numériques</p>
                    <p class="featured-desc skill-desc">Au sein des quatre types de modèles numériques, nous cherchons à voir des travaux sur leur découverte et comment assurer l'interopérabilité entre eux ?</p>
                </li>
                <li class="transition2">
                    <div class="icon-container three">
                        <img src="./images/connection.png" alt="UI/UX Icon"/>
                    </div>
                    <p class="skill-title">Connexions et Services de jumeaux numériques</p>
                    <p class="featured-desc skill-desc">Nous examinons des présentations qui fournissent des services de jumeau numérique tels que l'optimisation et la simulation, reliant également différentes dimensions d'un jumeau numérique.</p>
                </li>
            </ul>
        </div>
    </section>


    <section class='skills'>
			<h1>Keynotes</h1>
            <div class="skills-container">
            <ul>
                <li class="transition2">
                    <div class="icon-container one">
                    {/* <img class='image-keynote' src="https://media.licdn.com/dms/image/C4D03AQH1MPedPR9tXg/profile-displayphoto-shrink_800_800/0/1642536554572?e=2147483647&v=beta&t=jXVPDVYvzWZzM_cqUBX7J4wpUnvSb8RY8b71on4Wzzo" alt="" /> */}<img class='image-keynote-boss' src='./images/boss.png' ></img>

                    </div>
                    <p class="skill-title-keynote">DR. Birgit BOSS</p>
                    <p class="featured-desc skill-desc">Digital Twin senior scientist at Bosch. Dr. Boss has expertise in software Engineering and Software Architecture with more than 20 years experience in the development and roll out of architectures, methods and standards for complex embedded systems and Industrial IoT systems.
				Board member of the Industrial Digital Twin Association (IDTA), Chair of Working Group "Open Technology" and of the Task Force „Asset Administration Shell“ in the IDTA, Lead of the „Semantic Layer including Digital Twins“ working group of CATENA-X.
				Areas of expertise include Software Architecture, Software Product Line Development, Software Ecosystems, Industrial Internet of Things (IIoT), Connected Industry, Continuous Refactoring, Process Development, Standardization, AUTOSAR, Digital Twins and Industry 4.0.</p>
                </li>
                <li class="transition2">
                    <div class="icon-container one">
                    <img class='image-keynote' src='https://media.licdn.com/dms/image/C4E03AQE5JdEDOmucQQ/profile-displayphoto-shrink_800_800/0/1516560264963?e=2147483647&v=beta&t=so6Z7h13NdXrO49lZ87t79_pXOy1eGLRUo_MuiWibq4' ></img>
                    

                    </div>
                    <p class="skill-title-keynote">Jean-Luc GARNIER</p>
                    <p class="featured-desc skill-desc">Jean-Luc Garnier is Systems Engineering and Architecting Director within the Thales Technical Directorate. His domain of expertise is real-time distributed systems. He has an engineer degree in computer science from INSA (French Institute of Applied Science). From 1984 to 1999, he had been software engineer and expert in consulting companies, mainly in compilers, operating systems and real-time telecoms developments. He joined Thales in 2000 as system architect, successively in Integrated Modular Avionics, Electronic Warfare and Network Centric Warfare. He teaches System Architecting at the Thales University and in the Master program in Design & Management of Complex Information Systems (COMASIC) of The French Ecole Polytechnique,. He is also chairman of the 3S-AI (System of System and Service – Architecting and Engineering) Technical Committee of AFIS (French Chapter of INCOSE).</p>
                </li>
               
            </ul>
        
			</div>
			
		</section>

    <section style={{margin: "40px"}} className=''>
        
    <h2 style={{textDecoration: "underline"}} className="text-center mb-4">Les organisateurs</h2>
    <div className="sponsers">
            <div className="row g-4">
                <div className="col-md">
                    <ul className="sponsers">
                        <li className="list-group-pb">
                        </li>
                        <li className="list-group-pb">
                            <img style={{width:'200px'}} src="./images/IEEE.png" alt="" />
                        </li>
                        <li className="list-group-pb">
                            <img style={{width:'200px'}} src="./images/sagip.png" alt="" />
                        </li>
                        <li className="list-group-pb">
                            <img style={{width:'200px'}} src="./images/pgso.jpg" alt="" />
                        </li>
                        <li className="list-group-pb">
                            <img style={{width:'200px'}} src="./images/enitLogo.jpg" alt="" />
                        </li>
                        <li className="list-group-pb">
                            <img style={{width:'200px'}} src="./images/lgp.png" alt="" />
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    </section>

     {/* contact */}

     <section style={{margin: "40px"}} className=''>
            <div className="container">
            <div className="row g-4">
                <div className="col-md">
                    <h2 className="text-center mb-4">Contact the event chair</h2>
                    <ul className="pb-contact">
                        <li className="list-group-pb">
                            {/* <span className="fw-bold"> Rue DE RABANEAU
                        33440 AMBARES-ET-LAGRAVE</span> */}
                        </li>
                        <li className="list-group-pb">
                        <a href="mailto: sina.namakiaraghi@enit.fr">Sina NAMAKI ARAGHI 👈 </a> 
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </section>

        {/* <PostModal showModal={showModal} handleClick={handleClick}/> */}
        </div>
        
// Sponsers


   
	);
};



// 
// const mapStateToProps = (state)=> ({firebase, firestore, demands, users})=> {
//     
//     return{
//         firebase,
//         loadingDemandPosting: demands.loading,   
//         // demands: state.demands.demands, ...> the problem was here I was looking at state then looking into demands ... they are already there   
//     }
// }
// 
// const mapDispatchToProps = (dispatch) =>({
//     // getDemands: ()=> dispatch(getDemandsAPI()),
// });
// 
// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;
