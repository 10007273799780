import React from "react";
import './Committee.css'
import {CardList} from '../card-list/card-list.component';

const Committee = () => {
	
	return (
		

		<section class='container'>
			<h1>Comité scientifique</h1>
			<div class="grid-container-organizers">
				<div class="grid-item">
							<img class='image' src="https://www.imt-mines-albi.fr/sites/default/files/styles/image_300px/public/thumbnails/image/capture_decran_2021-04-09_a_11.12.45.png?itok=A0_YV4iC" alt="" />

							<h2 className="card-title mb-3">DR. Sina NAMAKI ARAGHI</h2>
										<p className="card-text">Associate professor à l'ENIT.</p>
										<p className="card-text-resp">Coprésident</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://fr.linkedin.com/in/sina-namaki-araghi"><i class="fab fa-linkedin-in"></i></a>
				</div>
				<div class="grid-item">
							<img class='image' src="https://i1.rgstatic.net/ii/profile.image/723789784559617-1549576340731_Q512/Hedi-Karray.jpg" alt="" />

							<h2 className="card-title mb-3">Prof.DR.  Mohammed-Hedi KARRAY</h2>
										<p className="card-text">Directeur de l'équipe de recherche PICS au laboratoire LGP de l'ENIT.</p>
										<p className="card-text-resp">Coprésident</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/hkarray"><i class="fab fa-linkedin-in"></i></a>
				</div>

				<div class="grid-item">
							<img class='image' src="https://www.u-bordeaux.fr/application/files/cache/thumbnails/3bbf846283cb4b92220e58af03629263.jpg" alt="" />

							<h2 className="card-title mb-3">Prof.DR.  Yves DUCQ</h2>
										<p className="card-text">Vice-président de l'Université de Bordeaux et Directeur d'InteroVlab PGSO</p>
										<p className="card-text">Membre de l'équipe d'organisation</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/yves-ducq-1492b918"><i class="fab fa-linkedin-in"></i></a>
				</div>

				<div class="grid-item">
							<img class='image' src="https://images.ladepeche.fr/api/v1/images/view/5c2e925a8fe56f49a17aeedd/full/image.jpg" alt="" />

							<h2 className="card-title mb-3">Prof.DR.  Bernard ARCHIMEDE</h2>
										<p className="card-text">Directeur du laboratoire LGP de l'ENIT.</p>
										<p className="card-text">Membre de l'équipe d'organisation</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/bernard-archimede-4533b119"><i class="fab fa-linkedin-in"></i></a>
				</div>
				<div class="grid-item">
							<img class='image' src="https://media.licdn.com/dms/image/C4D03AQGDgU1aMi0K3Q/profile-displayphoto-shrink_800_800/0/1517552493129?e=2147483647&v=beta&t=dDTMFuN7aS_twluE3u0M7cn-VfWm67Ot0qhleSDBxlY" alt="" />

							<h2 className="card-title mb-3">Prof.DR.  Mamadou KABA TRAORÉ</h2>
										<p className="card-text">Responsable du comité technique du jumeau numérique chez SAGIP.</p>
										<p className="card-text">Membre de l'équipe d'organisation</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/mamadou-kaba-traor%C3%A9"><i class="fab fa-linkedin-in"></i></a>
				</div>
				<div class="grid-item">
							<img class='image' src="https://cdn.theconversation.com/avatars/1289009/width238/image-20220604-59196-ui5ebr.jpg" alt="" />

							<h2 className="card-title mb-3">Prof.DR.  Gregory ZACHAREWICZ</h2>
										<p className="card-text">Responsable du comité technique du SIMPA (SIMulation, Performance et Amélioration) chez SAGIP.</p>
										<p className="card-text">Membre de l'équipe d'organisation</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/gregoryzacharewicz"><i class="fab fa-linkedin-in"></i></a>
				</div>
				<div class="grid-item">
							<img class='image' src="https://iames2022.sciencesconf.org/data/pages/Portrait_Francois_2.png" alt="" />

							<h2 className="card-title mb-3">Prof.DR.  François PERES </h2>
										<p className="card-text">Directeur de la SAGIP.</p>
										<p className="card-text">Membre de l'équipe d'organisation</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/francoisperes"><i class="fab fa-linkedin-in"></i></a>
				</div>
				{/*  */}
				<div class="grid-item">
							<img class='image' src="https://perso.imt-mines-albi.fr/~fontanil/ID_FranckCoul_2016.jpg" alt="" />

							<h2 className="card-title mb-3">DR. Franck FONTANILI</h2>
										<p className="card-text">Directeur du groupe de recherche IOS au laboratoire CGI d'IMT Mines Albi</p>
										<p className="card-text">Membre de l'équipe d'organisation</p>
										{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i class="fa-brands fa-x-twitter"></i></a> */}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/franck-fontanili-70546743"><i class="fab fa-linkedin-in"></i></a>
				</div>
				
				
		
			</div>
			
		</section>



	);
};

export default Committee;



// {/* </div>
// 		<section id='team' className='cardSection'>
// 		<div className='container'>
// 		<h2 class="text-center text-white">Keynotes</h2>
// 		<p class="lead text-center text-white mb-5"></p>
// 
// 		<div className="row">
// 			<div className='col-md-6 col-lg-3'>
// 				<div className="card bg-white">
// 					<div className="card-body text-success text-center">
// 					
// 						
// 						<h3 className="card-title mb-3">Jean-Luc GARNIER </h3>
// 						<p className="card-text">Jean-Luc Garnier is Systems Engineering and Architecting Director within the Thales Technical Directorate. His domain of expertise is real-time distributed systems. He has an engineer degree in computer science from INSA (French Institute of Applied Science). From 1984 to 1999, he had been software engineer and expert in consulting companies, mainly in compilers, operating systems and real-time telecoms developments. He joined Thales in 2000 as system architect, successively in Integrated Modular Avionics, Electronic Warfare and Network Centric Warfare. He teaches System Architecting at the Thales University and in the Master program in Design & Management of Complex Information Systems (COMASIC) of The French Ecole Polytechnique,. He is also chairman of the 3S-AI (System of System and Service – Architecting and Engineering) Technical Committee of AFIS (French Chapter of INCOSE).</p>
// 						{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i className="fab fa-twitter"></i></a> */}
// 						<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jean-luc-garnier-73a92575"><i class="fab fa-linkedin-in"></i></a>
// 					</div>
// 					
// 				</div>
// 			</div>
// {/* second team member */}
// 			<div className="col-md-6 col-lg-3">
// 				 <div className="card bg-white">
// 					<div className="card-body text-success text-center">
// 						<img src="https://media.licdn.com/dms/image/C4D03AQH1MPedPR9tXg/profile-displayphoto-shrink_800_800/0/1642536554572?e=2147483647&v=beta&t=jXVPDVYvzWZzM_cqUBX7J4wpUnvSb8RY8b71on4Wzzo"
// 						className="rounded-circle mb-3"></img>
// 						<h3 className="card-title mb-3">DR. Birgit BOSS</h3>
// 						<p className="card-text">Software Engineer and Software Architect with more than 20 years experience in the development and roll out of architectures, methods and standards for complex embedded systems and Industrial IoT systems.
// Board member of the Industrial Digital Twin Association (IDTA), Chair of Working Group "Open Technology" and of the Task Force „Asset Administration Shell“ in the IDTA, Lead of the „Semantic Layer including Digital Twins“ working group of CATENA-X.
// Areas of expertise include Software Architecture, Software Product Line Development, Software Ecosystems, Industrial Internet of Things (IIoT), Connected Industry, Continuous Refactoring, Process Development, Standardization, AUTOSAR, Digital Twins and Industry 4.0.</p>
// 						{/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i className="bi bi-twitter text-dark mx-1"></i></a> */}
// 						<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/birgit-boss"><i class="fab fa-linkedin-in"></i></a>
// 					</div>
// 				 </div>
// 			</div>
// 		</div>
// 		
// 		</div>
// 	</section>
// 	</div> 