import React from 'react';
import styled from 'styled-components'
import Navbar from '../../../Components/Navbar/Navbar/Navbar';
import SideDrawer from '../../../Components/Navbar/Navbar/SideDrawer/SideDrawer';
// import { connect } from 'react-redux';

const MainWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 6rem);
    margin-top: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Layout = ({ children }) => (
  <>
    <Navbar />
    <SideDrawer  />
    <MainWrapper>{children}</MainWrapper>
  </>
);



export default Layout;