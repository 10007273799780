import React from 'react';
import styled from 'styled-components';

const Blogs = () => {
	return (
		<Container>

			<Image src="./images/enit.jpg" alt="" />

			<h1 >COMMENT REJOINDRE L'ENIT (47, avenue d'Azereix - BP 1629 - 65016 Tarbes CEDEX)</h1>
			

		<H>Par la route</H>
			<h3>✓ Prendre à droite direction Tarbes Centre Ville au 1er rond-point, puis continuer tout droit jusqu'au rond-point de l'université, puis suivre les panneaux vers le campus universitaire.</h3>
			<h3>✓ L'entrée du campus universitaire se trouve quelques mètres plus loin sur votre gauche (passez devant l'Observatoire puis prenez la première à droite pour accéder au parking à côté du gymnase).</h3>

			<h3>✓ L'ENIT est également accessible par l'entrée Place de l'Ingénieur en descendant quelques centaines de mètres par l'Avenue d'Azereix.
			</h3>
		<H>Par avion</H>

		<h3>✓ Aéroport de Tarbes-Ossun-Lourdes (à 8 km du campus) : une fois arrivé, vous pouvez prendre un taxi ou louer une voiture.</h3>
		<h3>✓ Aéroport Pau-Pyrénées (à 45 km du campus) : une fois arrivé, vous pouvez prendre des navettes ou le train jusqu'à Tarbes (https://m.ter.sncf.com/occitanie/) ou louer une voiture.</h3>
		<h3>✓ Aéroport Toulouse-Blagnac (à 162 km du campus) : une fois arrivé, vous pouvez prendre le bus ou le tramawy de l'aéroport jusqu'à la SNCF (https://www.toulouse.aeroport.fr/fr/transports/public-transport) , puis prendre les navettes ou le train jusqu'à Tarbes (https://m.ter.sncf.com/occitanie/) ou louer une voiture.</h3>

		<H>Transport public</H>
		<h3>✓ Réseau de bus du Grand Tarbes : le T3 relie le centre-ville au campus universitaire.</h3>


		
		<h1 style={{paddingTop:'60px'}}>ACCOMODATIONS</h1>
		<h4>L'organisation de la conférence ne gère pas les réservations d'hôtel, mais vous recommande de séjourner dans un hôtel de la liste ci-dessous.</h4>

		<center><table width="100%" border="0">

						<tbody><tr>

						<td width="20%"><center>Hotel name</center></td>


						<td width="20%"><center>Website</center></td>

						</tr>

						

						<tr>

						<td width="20%"><center>Le REX Hôtel ★ ★ ★ ★</center></td>

						
						<td width="20%"><center><a href="https://www.lerexhotel.com/">www.lerexhotel.com</a></center></td>

						</tr>

						

						<tr>

						<td width="20%"><center> Le NEXHOTEL ★ ★  ★</center></td>

						

						<td width="20%"><center><a href="http://www.lenexhotel.com/">www.lenexhotel.com</a></center></td>

						</tr>

						

						

						<tr>

                        <td width="20%"><center>Hôtel FOCH ★ ★ ★ </center></td>

                        
                        <td width="20%"><center><a href="http://www.hotel-foch.eu/fr/"> www.hotel-foch.eu [hotelfoch@gmail.com]</a></center></td>

						</tr>

						

						<tr>

						<td width="20%"><center>Hôtel La Croix Blanche ★ ★ </center></td>

						

						<td width="20%"><center><a href="https://www.hotel-lacroixblanche.fr/">www.hotel-lacroixblanche.fr</a></center></td>

						</tr>

						

						<tr>

						<td width="20%"><center>Le Nex2 Hôtel ★ ★ </center></td>

						

						<td width="20%"><center><a href="http://www.lenex2.com/accueil">www.lenex2.com</a></center></td>

						</tr>

						

						

																		

						</tbody></table></center>
		</Container>
	);
};

const Container = styled.div`
background: #fff;
  color: #111111;
  display: block;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  font-weight: bold;
  padding: 4em;
  margin-top: 0.8rem;
  font-size: 1.2rem;
`;

const H = styled.h1`
color: #6e00ff;
border-bottom: 1px solid #6e00ff;
padding-bottom: 5px;
padding: 20px
`;

const Image = styled.img`
margin-left: 2em;
margin-top: 0em;
border-radius: 20px;
width: 500px;
@media (max-width: 768px) {
    width : 200px;
  }
`;

export default Blogs;
