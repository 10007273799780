
const theme = {
    colors: {
        main: '#003459',
        mainDark: '#21283e',
        mainLight: '#2c3247',
        mainLighter: '#2f82b8',
        textColor: '#333',
        whiteColor: '#fff',
        errorRed: '#ff5757',
        shadow: 'rgba(0,0,0,.2)',
    },

    mediaQueries: {
        smallest: `only screen and (max-width: 25em)`,
        small: `only screen and (max-width: 37.5em)`,
        medium: `only screen and (max-width: 56em)`,
        large: `only screen and (max-width: 80em)`,
        largest: `only screen and (max-width: 90em)`,
      },
};

export default theme;