import React from 'react';
import styled from 'styled-components';

import NavItem from './NavItem/NavItem';
// import { connect } from 'react-redux';


const Nav = styled.nav`
  display: flex;
  margin-top: ${props => (props.mobile ? '-6rem' : null)};
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: ${props => (props.mobile ? 'column' : 'row')};
  align-items: center;
  height: 100%;
`;

const NavItems = ({ mobile, clicked }) => {
  let links;
 
    links = (
      <Ul mobile={mobile}>

        <NavItem mobile={mobile} clicked={clicked} link="/">
          
        <span>Page d'accueil</span>
          <img src="/images/register.png" alt="" />
        </NavItem>
        <NavItem mobile={mobile} clicked={clicked} link="/ConferenceProgram">
          <span>Programme</span>
          <img src="/images/plan.png" alt="" />
        </NavItem>
        <NavItem mobile={mobile} clicked={clicked} link="/committee">
         
          <span>Comités</span>
          <img src="/images/leader.png"  alt="" />
        </NavItem>
        <NavItem mobile={mobile} clicked={clicked} link="/venue">
          <span>Venue</span>
          <img src='/images/directions.png'/>
        </NavItem>
        
      </Ul>
    );
  return <Nav mobile={mobile}>{links}</Nav>;
};

export default NavItems;