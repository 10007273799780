// src/components/ConferenceProgram.js

import React, { useState } from 'react';
import './styles.css';
import Calendar from './JNJN.ical.zip';

function ConferenceProgram() {
  const [showDay1, setShowDay1] = useState(true);
  const [currentDate, setCurrentDate] = useState("Programme du 23 novembre");
  const [buttonColorClass, setButtonColorClass] = useState("button-blue"); // Default color class


  const toggleProgram = () => {
    setShowDay1(!showDay1);
    // Toggle between "22nd of November" and "23rd of November
    setCurrentDate(currentDate === "Programme du 23 novembre" ? "Programme du 22 novembre" : "Programme du 23 novembre");
    setButtonColorClass(buttonColorClass === "button-blue" ? "button-red" : "button-blue");
 
  };

  return (
    <div className="conference-program">
      <h1>Le programme </h1>
      {/* <p>Date: [Date]</p>
      <p>Location: [Location]</p> */}

      <div className='btnDiv'>

        <button onClick={toggleProgram} className={`toggle-button ${buttonColorClass}`}>
          {currentDate}
        </button>
        <button  className="dl-button"><a style={ {color:'#fff'}} href={Calendar} download="myFile">Télécharger iCal</a></button> 

      </div>
     

      <div className={`program-content ${showDay1 ? 'active' : ''}`}>
        <h1>Programme du premier jour - Le 22 novembre</h1>
        <div className="event">
          <h3>L'accueil </h3>
          <p>Heure : [8:45] - [9:15]</p>
          <p>Lieu: Le hall du bâtiment D</p>
          <h2>Présentation 1 : Mots d'ouverture</h2>
          <p>Heure: [9:15] - [9:30]</p>
          <p>Lieu: [Bâtiment C - Amphi B]</p>
          {/* <p>Speaker: [Sina NAMAKI ARAGHI]</p> */}
          <div className='theme'><h2 >Thème 1 : Architecture des jumeaux numériques et concepts fondamentaux</h2></div>
          
          <h3>Keynote : Digital Twins in Data Ecosystems</h3>
          <p>Heure: [9:30] - [10:30]</p>
          <p>Lieu: [Bâtiment C - Amphi B]</p>
          <p>Speaker: [DR. Birgit BOSS]</p>
        </div>

        <div className="event">
          <h4>Pause café</h4>
        <p>Time: [10:30] - [11:00]</p>
          <p>Lieu: [Bâtiment D - Salle du conseil]</p>
        </div>
        <div className="event">
          <h3>Présentation 2: An architectural framework to assess the maturity level of digital twins </h3>
          <p>Time: [11:00] - [11:45]</p>
          <p>Speaker: [Sina NAMAKI ARAGHI]</p>
          <p>Lieu: [Bâtiment C - Amphi B]</p>
        </div>

        <div className="event">
          <h3>Présentation 3: Ontological concerns of Digital Twins</h3>
          <p>Time: [11:45] - [12:15]</p>
          <p>Speaker: [Arkopaul SARKAR]</p>
          <p>Lieu: [Bâtiment C - Amphi B]</p>

        </div>

        <div className="eventAfterNoon">
          <h4>Déjeuner</h4>
          <p>Time: [12:15] - [14:00]</p>
          <p>Lieu: [Bâtiment D - Salle du conseil]</p>
        </div>
        
        <div className="event">
        
          <h3>Présentation 4: proposition d’une définition et d’une classification des jumeaux numérique orientées use cases et industrie</h3>
          <p>Time: [14:00] - [14:30]</p>
          <p>Speaker: [Emmanuelle ABISSET-CHAVANNE]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>

        </div>

        <div className="event">
        <h3>Présentation 5: Vers le calcul en temps réel pour le jumeau numérique des procédés thermomécaniques complexes. </h3>
          <p>Time: [14:30] - [15:00]</p>
          <p>Speaker: [Amèvi TONGNE, Bernard KAMSU-FOGUEM]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>
        
        <div className='theme'>
        <h2>Thème 2 : Connexions entre les différentes dimensions des jumeaux numériques</h2>
        </div>
        <div className="event">
          
        
          <h3>Présentation 6 : Un jumeau numérique pour améliorer la connaissance de la situation et la prise de décision en gestion de crise </h3>
          <p>Time: [15:00] - [15:30]</p>
          <p>Speakers: [Audrey Fertier et Aurélie Congès]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>

        </div>

        <div className="event">
          <h4>Pause café</h4>
        <p>Time: [15:30] - [16:00]</p>
          <p>Lieu: [Bâtiment D - Salle du conseil]</p>
        </div>

        <div className="event">
          
        
          <h3>Présentation 7 : Méthodologie de validation précoce intégrant les jumeaux numériques dans le processus de développement des systèmes mécatroniques</h3>
          <p>Time: [16:00] - [16:30]</p>
          <p>Speaker: [ Imane BOUHALI ]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>

        </div>

        <div className="event">
          
        
          <h3>Présentation 8 : Synergistic Fusion of Simulation and Virtual Reality: A new proposed Approach for Collaborative Integration-  </h3>
          <p>Time: [16:30] - [17:00]</p>
          <p>Speakers: [Jalal Possik et Joseph Jabbour]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>

        </div>
      </div>



     {/* SECOND day */}

      <div className={`program-content ${!showDay1 ? 'active' : ''}`}>
        <h1>Programme de la deuxième journée - Le 23 novembre</h1>
        <div className="event">
          <h3>L'accueil </h3>
          <p>Heure : [9:00] - [9:30]</p>
          <p>Lieu: Le hall du bâtiment D</p>
          <div className='theme'>
          <h2>Thème 3 : Découverte et interopérabilité des modèles numériques</h2>
          
          </div>
          <h3>Keynote : Principles governing development and usage of Digital Twins </h3>
          <p>Heure: [9:30] - [10:30]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
          <p>Speaker: [Jean-Luc GARNIER]</p>
        </div>

        <div className="event">
          <h4>Pause café</h4>
        <p>Time: [10:30] - [11:00]</p>
          <p>Lieu: [Bâtiment D - Salle du conseil]</p>
        </div>

        <div className="event">
        <h3>Présentation 9: De la Simulation au Jumeau Numérique : Evolution ou Révolution ?</h3>
          <p>Time: [11:00] - [11:30]</p>
          <p>Speaker: [Mamadou KABA TRAORÉ]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>

        <div className="event">
        <h3>Présentation 10: Stratégie nationale pour le développement des jumeaux numériques</h3>
          <p>Time: [11:30] - [12:00]</p>
          <p>Speaker: [Mohammed-Hedi KARRAY]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>

        <div className="eventAfterNoon">
          <h4>Déjeuner</h4>
          <p>Time: [12:15] - [14:00]</p>
          <p>Lieu: [Bâtiment D - Salle du conseil]</p>
        </div>

        <div className="event">
        <h3>Présentation 11: Digitalisation d’un Hub logistique industriel 4.0 : démarche et objectifs vers un « Jumeau Numérique Générique » </h3>
          <p>Time: [14:00] - [14:30]</p>
          <p>Speaker: [Société Daher : Mahdi MOVAHEDKHAH ]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>
        
        <div className="event">
        <h3>Présentation 12: Du diagnostic organisationnel au pilotage prédictif des parcours patients à l'hôpital - Une proposition de système cyber-physique à base de doubles numériques </h3>
          <p>Time: [14:30] - [15:00]</p>
          <p>Speaker: [Franck FONTANILI ]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>
        
        <div className="event">
        <h3>Présentation 13: Digital twin services for PHM : Physics informed machine learning based characterisation of rotor system degradation behaviour </h3>
          <p>Time: [15:00] - [15:30]</p>
          <p>Speaker: [Weikun DENG]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>

        <div className="event">
          <h4>Pause café</h4>
        <p>Time: [15:30] - [16:00]</p>
          <p>Lieu: [Bâtiment D - Salle du conseil]</p>
        </div>

        

        <div className="event">
        <h3>Présentation 14: Workflows at the heart of digital twins </h3>
          <p>Time: [16:00] - [16:30]</p>
          <p>Speaker: [Gregory Zacharewicz ]</p>
          <p>Lieu: [Bâtiment D - Grand Amphi]</p>
        </div>

        <div className="event">
          <h3>Mot de clôture</h3>
          <p>Time: [16:30] - [17:00]</p>
        </div>
      </div>
    </div>
  );
}

export default ConferenceProgram;
