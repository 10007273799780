import React from 'react';
import Logo from '../../Logo/Logo';
import {Container} from '../../../hoc/Layout/elements';
import styled from 'styled-components';
import NavItems from './NavItems/NavItems';
// import { connect } from 'react-redux';

const FixedWrapper = styled.div`
    position : sticky;
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem;
    background-color: #6e00ff ;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    z-index:1;

    

    @media (max-width: 768px){
        display: none;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
`;

const Navbar = ({  }) => {
    return (
      <FixedWrapper>
        <Container>
          <Wrapper>
            <Logo />
            <NavItems />
          </Wrapper>
        </Container>
      </FixedWrapper>
    );
  };




export default  Navbar
